import React from "react"
import Button from "../../../components/Button"

import Dash from "../../../components/Dash"
import { CustomMarkdown } from "../../../plugins/sanitizehtml"

const Choice = ({ props, dashColor, general, lang }) => {
  const { title, content, cta } = props
  const { template, buttonLabel, url } = cta

  return (
    <div className={`my-3 py-4`}>
      <div
        // className={`row justify-content-center`}
        style={{
          paddingLeft: "calc(100vw / 12)",
          paddingRight: "calc(100vw / 12)",
        }}
      >
        <h2 className={`col-12 text-center ming lora-Bold`}>
          <CustomMarkdown html={title} color="copper"></CustomMarkdown>
        </h2>
        <Dash className="col-sm-12" colorClass={dashColor ?? ""} />
        <div className="col-lg-12 mb-4 text-center">
          <div>{content}</div>
        </div>
        <div className="row justify-content-center">
          <Button
            buttonId={`hero_quote_cta`}
            lang={lang}
            type={template}
            text={buttonLabel}
            url={url}
          />
        </div>
      </div>
    </div>
  )
}

export default Choice
