import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Dash from "../../../components/Dash"
import Img from "../../../components/Img"

const ChooseUsNoSep = ({ data, dashColor }) => {
  const { title, verticalItems } = data

  const leftGreen = "../../../images/choose-us/left-green.png"
  const leftCopper = "../../../images/choose-us/left-copper.png"

  return (
    <div>
      <div className="position-relative">
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src={leftCopper}
          alt="alea"
          className="d-none d-xl-block"
          style={{ position: "absolute", top: "60px", zIndex: -40 }}
        />
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src={leftGreen}
          alt="alea"
          className="d-none d-lg-block"
          style={{ position: "absolute", top: "60px", zIndex: -30  }}
        />
      </div>
      <div
        className={` mb-4`}
        style={{
          paddingLeft: "calc(100vw / 12)",
          paddingRight: "calc(100vw / 12)",
        }}
      >
        <h2 className="pt-5 ming text-center mb-4">{title}</h2>
        <Dash className="col-sm-12" colorClass={dashColor ?? ""} />

        <div className="col-12 flex flex-wrap gap-5 justify-content-center">
          {verticalItems.map((item, index) => (
            <div key={index} className="col-12 col-lg-3 pb-4 px-5 px-lg-2 ">
              <div className="flex justify-content-center">
                <Img image={item.image} className="col-4 p-2 p-lg-1" />
              </div>

              <h4 className={`col-12 ming text-center`}>{item.title}</h4>

              <div className={`col-12 ming text-center`}>{item.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChooseUsNoSep
