import React, { useEffect, useState } from "react"
import Topic from "./Topic"
import { staticRoutes } from "../../../enumerator/staticRoutes"
import { updateUrlByLocale } from "../../../plugins/common"
import { Link } from "gatsby"

const Topics = ({ data, translation, lang, type }) => {
  const LIMIT_TOPIC = 6
  const { title, topicCard } = data
  const { seeMoreButton, seeLessButton } = translation

  const showseeMore = topicCard.length > LIMIT_TOPIC
  const [seeMore, setSeeMore] = useState(topicCard.length > LIMIT_TOPIC)
  const [topicsNum, setTopicsNum] = useState(
    topicCard.length > LIMIT_TOPIC ? LIMIT_TOPIC : topicCard.length
  )

  const handleSeeMore = () => {
    if (seeMore) {
      setTopicsNum(topicCard.length)
      setSeeMore(false)
      return
    }
    setTopicsNum(LIMIT_TOPIC)
    setSeeMore(true)
  }

  useEffect(
    () => setSeeMore(!(topicCard.length <= LIMIT_TOPIC)),
    [topicCard.length]
  )

  const urlLink = (slug1, slug2, slug3, parentSlug = "resources") => {
    if (slug1 && slug1.length > 0) return slug1
    if (slug2 && slug2.length > 0) return "/" + parentSlug + "/" + slug2
    if (slug3 && slug3.length > 0) return staticRoutes.REVIEWS + slug3
    return "/"
  }

  // Types
  // 1. white-beigeBG
  // 2. white-whiteBG
  // 3. beige-whiteBG
  // 4. beige-beigeBG

  return (
    <div
      className={`my-3 py-4 ${
        type === "white-whiteBG" || type === "beige-whiteBG"
          ? "bg-white"
          : "bg-beige2"
      }`}
    >
      <div
        className={`flex flex-wrap`}
        style={{
          paddingLeft: "calc(100vw / 12)",
          paddingRight: "calc(100vw / 12)",
        }}
      >
        <h2 className={`col-12 text-center ming lora-Bold`}>{title} </h2>
        {topicCard.slice(0, topicsNum).map((topic, index) => (
          <React.Fragment key={index}>
            {topic.article?.slug ||
            topic.review?.slug ||
            topic?.customInternalLink ? (
              <div className={`col-12 col-lg-6 cursor-pointer `}>
                <Link
                  className="tw-no-underline"
                  to={updateUrlByLocale(
                    urlLink(
                      topic?.customInternalLink,
                      topic.article?.slug,
                      topic.review?.slug,
                      topic.article?.altParentHubSlug?.slug
                    ),
                    lang
                  )}
                >
                  <Topic data={topic} type={type} lang={lang} />
                </Link>
              </div>
            ) : (
              <div className={`col-12 col-lg-6`}>
                <Topic data={topic} type={type} lang={lang} />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      {showseeMore && (
        <div
          className={`text-center openSans-SemiBold`}
          onClick={handleSeeMore}
          onKeyDown={handleSeeMore}
          role={`button`}
          tabIndex={-1}
          style={{ cursor: "pointer" }}
        >
          {seeMore ? seeMoreButton : seeLessButton}
        </div>
      )}
    </div>
  )
}

export default Topics
