import React, { useMemo } from "react"

import Layout from "../../components/Layout"
import { getAllLocales, getCurrentLanguagePage } from "../../plugins/common"
import Header from "../../components/vhis/Header"
import Topics from "./components/Topics"
import Choice from "./components/Choice"
import Video from "./components/Video"
import Shelf from "./components/Shelf"
import ContentImage from "./components/ContentImage"
import ChooseUs from "./components/ChooseUs"
import Partners from "./components/Partners"
import { LanguageEnum } from "../../enumerator/language"
import { DynamicZone } from "../../enumerator/DynamicZone"
import ComparisonTable from "../../components/homepageV2/ComparisonTable"
import HubHeader from "./components/hubHeader"

const ResourceHub = ({ pageContext, location }) => {
  const {
    node,
    general,
    partnersList,
    translation,
    resourcesHubList,
    footerForm,
  } = pageContext
  const { form } = translation

  const empowerDashColor = "bg-copper"
  const languageOptions =
    general.allStrapiLanguages?.nodes ?? LanguageEnum.ENGLISH
  const lang = getCurrentLanguagePage(location, languageOptions)
  const aleaToken = pageContext.general.aleaToken
  const comparisonTables = general.allStrapiComparisons?.nodes

  const getComparisonTable = id => {
    if (comparisonTables && comparisonTables.length) {
      return comparisonTables.find(com => com.strapiId === id)
    }
    return null
  }

  const quoteForms = {
    ...node.quoteForm,
    ...footerForm.freeQuote,
  }

  node.dynamicZone.map(section => {
    section.topicCard?.map(card => {
      if (card.article?.altParentHubSlug) {
        card.article.altParentHubSlug = resourcesHubList.find(
          f => f.id === card.article.altParentHubSlug
        )
      }
    })
  })

  return (
    <Layout
      props={pageContext.general}
      meta={node.meta}
      localizations={getAllLocales(node.localizations, node.locale)}
      location={location}
      lang={lang}
      hideNewsletter={false}
      token={aleaToken}
      isB2B={node.isB2B}
    >
      {quoteForms?.enableHeader ? (
        <HubHeader
          data={node.header}
          quoteForm={quoteForms}
          locale={node.locale}
          token={aleaToken}
          location={location}
        />
      ) : (
        <Header data={node.header} lang={lang} />
      )}
      {node.dynamicZone.map((section, index) => (
        <div key={index}>
          {(section.strapi_component === DynamicZone.RESOURCE_TOPIC && (
            <Topics
              data={section}
              translation={form}
              lang={lang}
              type={"white-whiteBG"}
            />
          )) ||
            (section.strapi_component === DynamicZone.RESOURCE_CONTENT && (
              <Choice
                props={section}
                dashColor={empowerDashColor}
                lang={lang}
                general={pageContext.general}
              />
            )) ||
            (section.strapi_component === DynamicZone.RESOURCE_VIDEO && (
              <Video props={section} dashColor={empowerDashColor} lang={lang} />
            )) ||
            (section.strapi_component ===
              DynamicZone.RESOURCE_CONTENT_IMAGE && (
              <ContentImage
                props={section}
                dashColor={empowerDashColor}
                lang={lang}
              />
            )) ||
            (section.strapi_component === DynamicZone.RESOURCE_CHOOSE_US && (
              <ChooseUs
                props={section}
                dashColor={empowerDashColor}
                lang={lang}
                general={pageContext.general}
              />
            )) ||
            (section.strapi_component === DynamicZone.RESSOURCE_PARTNERS && (
              <Partners data={partnersList} lang={lang} type={"affiliate"} />
            )) ||
            (section.strapi_component === DynamicZone.HOMPAGE_COMPARISON && (
              <ComparisonTable
                lang={lang}
                data={getComparisonTable(section.comparison.id)}
              />
            ))}
        </div>
      ))}
      <Shelf props={node.footer} lang={lang}></Shelf>
    </Layout>
  )
}

export default ResourceHub
