import React from "react"

const Topic = ({ data, type }) => {
  const { title, content, icon } = data

  return (
    <div
      className={`topicArea m-2 tw-px-5 flex text-right ${
        icon ? "tw-py-3" : "tw-py-4 tw-pl-10"
      } ${
        type === "white-beigeBG" || type === "white-whiteBG"
          ? "bg-white"
          : "bg-beige2"
      }`}
    >
      {icon && (
        <div
          className={`md:tw-p-2 tw-p-1 tw-pt-3`}
        >
          <img
            src={icon?.url}
            alt={icon?.name}
            width="50"
            // objectFit={"contain"}
          />
        </div>
      )}
      <div className={`col-lg-10 col-10 pr-2`}>
        <div className={` tw-font-bold ${type === "white" ? `black` : "ming"}`}>
          {title}
        </div>
        <div className={`openSans ${type === "white" ? `black` : "ming"}`}>
          {content}
        </div>
      </div>
    </div>
  )
}

export default Topic
