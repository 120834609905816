import React from "react"

import Dash from "../../../components/Dash"
import Products from "../../../components/products"
import { CustomMarkdown } from "../../../plugins/sanitizehtml"
import ChooseUsNoSep from "./ChooseUsNoSep"

const ChooseUs = ({ props, dashColor, lang, general }) => {
    const { title, showSeparator, verticalItems } = props


    return (
        <>
            {showSeparator ?
                <div className="choices my-3 py-4">
                    <div className="row">
                        <div className="col-lg-12 col-sm-10 m-auto title text-center ming">
                            <h2>
                                <CustomMarkdown html={title} color="copper"></CustomMarkdown>
                            </h2>
                        </div>
                    </div>
                    <Dash colorClass={dashColor ?? ""} />
                    <Products
                        choices={verticalItems}
                        general={general}
                        lang={lang}
                    />
                </div>
                :
                <ChooseUsNoSep data={props} dashColor={dashColor} />
            }
        </>
    )
}

export default ChooseUs