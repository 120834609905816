import React from "react"

import { getExternalImg } from "../../../plugins/common"
import Dash from "../../../components/Dash"
import { CustomMarkdown } from "../../../plugins/sanitizehtml"

const ContentImage = ({ props, dashColor, lang }) => {
    const { title, content, image } = props


    return (
        <div className={`my-3 py-4`}>
            <div
                // className={`row justify-content-center`}
                style={{
                    paddingLeft: "calc(100vw / 12)",
                    paddingRight: "calc(100vw / 12)",
                }}
            >
                <h2 className={`col-12 text-center ming lora-Bold`}><CustomMarkdown html={title} color="copper"></CustomMarkdown></h2>
                <Dash className="col-sm-12" colorClass={dashColor ?? ""} type={"dashBig"} />
                <div
                    className={`flex flex-wrap justify-content-center`}
                >
                    <div className="row col-12 col-lg-6 order-2 order-lg-1">
                        <div className="">
                            <img
                                src={getExternalImg(image)}
                                width="90%"
                                style={{ objectFit: 'contain' }}
                                alt=""
                            />
                        </div>
                    </div>

                    <div className="row align-items-center col-12 col-lg-6 order-1 order-lg-2">
                        <div className="col mx-5 text-right">
                            <div>{content}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentImage