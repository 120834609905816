import React from "react"

import Dash from "../../../components/Dash"
import { CustomMarkdown } from "../../../plugins/sanitizehtml"

const Video = ({ props, dashColor, lang }) => {
  const { title, content, videoUrl } = props

  return (
    <div className={`my-3 py-4`}>
      <div
        style={{
          paddingLeft: "calc(100vw / 12)",
          paddingRight: "calc(100vw / 12)",
        }}
      >
        <h2 className={`col-12 text-center ming lora-Bold`}>
          <CustomMarkdown html={title} color="copper"></CustomMarkdown>
        </h2>
        <Dash className="col-sm-12" colorClass={dashColor ?? ""} />
        <div className={`flex flex-wrap justify-content-center`}>
          <div className="col-12 col-lg-6 col-xl-5">
            {videoUrl && (
              <div
                className="p-4 position-relative"
                style={{ paddingBottom: "56.25%" }}
              >
                <iframe
                  className="position-absolue"
                  title="video"
                  style={{ top: "0", bottom: "0" }}
                  width="100%"
                  height="350"
                  src={videoUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
          <div className="row align-items-center col-12 col-lg-6 ">
            <div className="col mx-5 text-right ming">{content}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Video
