import React from "react"
import Button from "../../../components/Button"
import { getBackgroundImage, getGatsbyImage } from "../../../plugins/common"

import { GatsbyImage } from "gatsby-plugin-image"

const Shelf = ({ props, lang }) => {
  const { title, ctas, background, image } = props
  const bg = getBackgroundImage(background)
  const img = getGatsbyImage(image)

  return (
    <div className="d-none d-lg-block">
      <div
        className={"d-flex align-items-center"}
        style={{
          height: "500px",
          position: "relative",
          top: "100px",
          backgroundRepeat: "repeat-x",
          placeContent: "center",
          background: "round",
          backgroundImage: `url(${bg})`,
        }}
      >
        <div
          className={`flex flex-wrap justify-content-around`}
          style={{
            paddingLeft: "calc(100vw / 10)",
            paddingRight: "calc(100vw / 10)",
          }}
        >
          <div className="col-7">
            <h2 className={`col-12 text-right ming`}>{title}</h2>
            <div className={`row col-12 gap-5`}>
              {ctas.map((item, index) => (
                <div key={index} className={`col-4`}>
                  <Button
                    url={item.url}
                    text={item.buttonLabel}
                    type={item.template}
                    lang={lang}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-5 px-4">
            <GatsbyImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              image={img}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Shelf
